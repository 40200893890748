@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

//this is the only mechanism I could find to flag a specific 
//button in the nav header for styling.
// https://issues.amazon.com/issues/cce-4026
a[aria-label="We're hiring!"] span {
  color: #FFA500 !important;
  animation: gentleBounce 0.7s;
  animation-delay: 1.5s;
  &:hover {
    color: #FFA500 !important;
  }
}

//this I did because I'm a bad man.
// https://issues.amazon.com/issues/cce-4026
@keyframes gentleBounce {
  from, to { transform: scale(1, 1); }
  17% { transform: scale(0.85, 1.1); }
  33% { transform: scale(1.1, 0.85); }
  50% { transform: scale(1.2, 1.2); }
}

header {
  &>div{
    padding-left: 120px !important;
    padding-right: 120px;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  div[data-open=true] {
    top: 20px; /* Ensures dropdown menus match text size */
  }

  button > span {
    color: awsui.$color-text-home-header-default !important;
    font-size: 20px;
    font-weight: 500;
    line-height: normal !important;
    position: relative;
    top: -2px; /* Fixes misalignment caused by larger size */

    &:hover {
      color: awsui.$color-text-home-header-secondary !important;
    }
  }

  a > span {
    color: awsui.$color-text-home-header-default !important;
    font-size: 20px;
    font-weight: normal;

    &:hover {
      color: awsui.$color-text-home-header-secondary !important;
    }
  }
}

div[data-analytics-field-label] {
  div[data-analytics-field-label] {
    label {
      color: awsui.$color-border-input-default !important;
    }
  }
}

body {
  background-color: awsui.$color-background-cell-shaded;
  margin: 0;
}

footer {
  background-color: #232f3e;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 120px;
  padding-right: 120px;
  h4 {
    margin: 0px !important;
    color: awsui.$color-text-home-header-default !important;
  }

  .links {
    a {
      color: awsui.$color-text-home-header-default !important;
    }
  }

  .copy {
    color: awsui.$color-text-home-header-default !important;
  }

}

.full-event {
  & > div {
    padding-left: 100px !important;
    padding-top: 48px !important;
    padding-bottom: 48px !important;
    padding-right: 100px !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    border: none !important;
  }

  & > .shiftSection {
    padding-left: 120px !important;
    padding-top: 48px !important;
    padding-bottom: 48px !important;
    padding-right: 120px !important;

    h2 {
      span {
        font-size: 40px !important;
        font-weight: normal !important;
      }
    }

    & > div {
      margin-top: 40px !important;
    }
  }

  h1 {
    span {
      font-size: 40px !important;
    }
  }

  h2 {
    span {
      font-size: 40px !important;
      font-weight: normal !important;
    }
  }

  .full-subheader {
      font-size: 20px !important;
  }

  .location-badge {
    margin-left: 16px;
  }

  .go-back {
    a {
      font-weight: normal !important;
    }
  }

  .sidebarSection {
    > h3 {
      color: awsui.$color-charts-green-800;
      margin-top: 0px;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 8px;

      > span > svg {
        stroke-width: 1px !important;
      }
    }

    .sidebarContent {
      padding-left: 26px;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      .sidebar-option-margin {
        margin-top: 10px;
      }
    }
  }
}

.highlightedSearch {
  background-color: awsui.$color-background-layout-toggle-hover;
  position: relative;
  z-index: 10; // Needed to ensure dropdowns overlay other content
  label {
    font-size: 24px !important;
    color: awsui.$color-text-home-header-default !important;
  }
  padding: 64px;
}

.advancedSearch {
  margin: awsui.$space-scaled-s;
  
  button {
    background: #FFA500;
    outline-color: #FFA500 !important; 
  }

  .frequencyOptions, .activityTypeOptions {
    word-break: keep-all;
  }

  .title-subtext {
    padding-top: 2px;
    padding-bottom: 4px;
  }
}

// This creates padding on the edges of the site that has been specifically requested by Gil in the
// Figma design, even though it is outside of the usual Cloudscape design
.page-content {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 120px;
  padding-right: 120px;
}

.page-content-header {
  padding-bottom: 24px;
  h1 {
    span {
      font-size: 40px !important;
    }
  }
}

.more-ways {
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: awsui.$color-background-container-content;

  p {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  h2 {
    span {
      font-size: 40px !important;
      font-weight: normal !important;
    }
  }

  a {
    border: 1px solid awsui.$color-border-divider-default !important;
    border-radius: 0px !important;
    height: 100%;

    .boxbutton {
      display: inline-flex;
      align-items: center;
      height: 100%;

      .text-content {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: 24px;
        display: inline-block !important;
        color: awsui.$color-text-body-default !important;
      }
    }
  }
}

.more-ways-dark {
  @extend .more-ways;
  background-color: awsui.$color-background-cell-shaded;
}

.eventList {
  margin: awsui.$space-scaled-s;
}

.minor-text {
  color: awsui.$color-text-body-secondary;
}
.readmore-link {
  font-weight: normal !important;
}
.card-shifts {
  whitespace: nowrap;
}
.shift-card {
  border: 1px solid awsui.$color-text-body-default;
  padding: awsui.$space-static-s;
  min-width: 250px;
  height: 200px;
  display: inline-block;
  margin-right: awsui.$space-static-s;
  margin-top: awsui.$space-static-s;
}

.shiftBlurb {
  border-radius: awsui.$border-radius-badge;
  border-color: awsui.$color-border-button-normal-default;
  border-width: 2px;
  border-style: solid;
  padding-top: awsui.$space-static-xs;
  padding-bottom: awsui.$space-static-xs;
  padding-left: awsui.$space-static-s;
  padding-right: awsui.$space-static-s;
  background-color: awsui.$color-background-button-normal-default;
  color: awsui.$color-text-button-normal-default;
  text-decoration: none;
  display: inline-block;
  line-height: 12px;
  &:hover {
    color: awsui.$color-text-button-normal-hover;
    border-color: awsui.$color-border-button-normal-hover;
    background-color: awsui.$color-background-button-normal-hover
  }
}

.shift-options {
  margin-top: awsui.$space-static-s;
  margin-bottom: awsui.$space-static-s;
  float: right;
}

.card-shiftList {
  width: 100%; 
  height: 250px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  .viewMore {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: awsui.$color-background-container-content;
    padding-left: 16px;
    box-shadow: 0 0 32px 32px awsui.$color-background-container-content;
    line-height: 36px;
  }
}

.shiftList {
  height: 36px;
  padding-top: 2px;
  overflow: clip;
  position: relative;

  .viewMore {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: awsui.$color-background-container-content;
    padding-left: 16px;
    box-shadow: 0 0 32px 32px awsui.$color-background-container-content;
    line-height: 36px;
  }
}

.shiftSection {
  background-color: awsui.$color-background-cell-shaded;
  padding: awsui.$space-scaled-m;
}

.sidebar {
  padding-top: awsui.$space-static-s;
  h3 {
    color: awsui.$color-text-status-success !important;
  }

  h4 {
    font-size: 14px;
  }

  .sidebarContent {
    padding-left: 21px;
  }
}

.shiftBody {
  font-size: 14px;
  line-height: 14px;

  .shiftLocalDateTime {
    color: awsui.$color-charts-green-800 !important
  }
}

.shiftCard {
  li {
    > div {
      border-radius: 0px !important;
  
      &:after {
        border-block: none !important;
        border-inline: none !important;
      }
  
      border: 1px solid awsui.$color-text-body-default;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.breadcrumbs {
  padding-left: 120px;
}

.link-spacing {
  margin-top: 4px;
}

.registerLink {
    border-radius: awsui.$border-radius-badge;
    border-color: awsui.$color-text-link-default;
    border-width: 2px;
    border-style: solid;
    padding-top: awsui.$space-static-xs;
    padding-bottom: awsui.$space-static-xs;
    padding-left: awsui.$space-static-s;
    padding-right: awsui.$space-static-s;
    background-color: awsui.$color-background-button-normal-default;
    color: awsui.$color-text-link-default;
    text-decoration: none;
    text-align: center;
    line-height: 12px;
    display: block;
    font-size: 14px;
    &:hover {
      color: awsui.$color-text-link-hover;
      border-color: awsui.$color-text-link-hover;
      background-color: awsui.$color-background-button-normal-hover
    }
  }

.description {
  padding-left: awsui.$space-static-xxl;
  padding-right: awsui.$space-static-xxl;
  img {
    display: block;
    width: 100%;
  }
  sub {
    border-left: 4px solid awsui.$color-border-control-default;
    padding-left: 4px;
    font-size: 14px;
  }
}

.manageLinks {
  button {
    border-radius: awsui.$border-radius-dropdown !important;
    padding-left: 12px !important;
    border-color: awsui.$color-border-control-default !important;
    color: awsui.$color-text-body-default !important;
  }
  .manageGear {
    margin-right: 12px;
  }
  .manageInfo {
    button {
      border: none !important;
    }
  }
}
